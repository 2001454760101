import { intro_page } from "~/i18n/de/introPage";
import { bookshelf } from "~/i18n/de/bookshelf";
import { entries } from "~/i18n/de/entries";

const de = {
  library: "Bibliothek",
  workspace: "Arbeitsbereich",
  info_site: "Info-Seiten",
  misc: "Sonstiges",
  intro: "Intro",
  vision: "Vision",
  team: "Team",
  faq: "F.A.Q.",
  terms_and_conditions: "AGB",
  data_protection: "Datenschutz",
  imprint: "Impressum",
  schemata: "Schemata",
  feedback: "Feedback",
  offline: "Du bist offline",
  eraser: "Radierer",
  eraser_help_text: "Löschen: Auf Personalisierung klicken.",
  intro_page,
  bookshelf,
  entries,
};

export default de;
