export const useHeadlessUiIdStore = defineStore("headlessUiId", () => {
  const lastId = ref(0);

  const generateId = () => {
    lastId.value += 1;
    return lastId.value.toString();
  };

  return {
    generateId,
  };
});
