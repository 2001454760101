import type IArticle from "~/models/IArticle";
import type UpdateArticleDTO from "~/dto/updateArticleDTO";
import type CreateArticleDTO from "~/dto/createArticleDTO";
import { LexmeaApiClient } from "./apiClient";

export class ArticlesClient extends LexmeaApiClient {
  fetchArticles = () => {
    return this.get<IArticle[]>("api/article");
  };

  fetchArticleBySlug = (slug: string) => {
    return this.get<IArticle>(`api/article/${slug}`);
  };

  createArticle = (createDTO: CreateArticleDTO) => {
    return this.postForm<IArticle>("api/article/", createDTO);
  };

  updateArticle = ({
    id,
    updateDTO,
  }: {
    id: number;
    updateDTO: UpdateArticleDTO;
  }) => {
    return this.postForm<IArticle>(`api/article/${id}`, updateDTO, true);
  };

  destroyArticle = async (id: number): Promise<void> => {
    await this.delete(`api/article/${id}`);
  };
}
